import '@lib/theme/fonts/matter/matter.css';
import '@lib/theme/fonts/blacker/blacker.css';
import { NormalizedCacheObject } from '@apollo/client';
import { AppProps as NextAppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { ComponentType, useEffect, useMemo } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';

import { createApiGatewayClient } from '@/config/clientSideConfig';
import { useGlobalToastMessages } from '@/core/hooks/useGlobalToastMessages';
import { useVersionCheck } from '@/core/hooks/useVersionCheck';
import { cn } from '@/core/ui/utils';
import { TrackPageEvent } from '@/modules/analytics/components/TrackPageEvent';
import { UtmProvider } from '@/modules/analytics/components/UtmProvider';
import { useSegmentAnalytics } from '@/modules/analytics/hooks/useSegmentAnalytics';
import '@/config/logger'; // import the module to patch console.* methods
import { AuthProvider } from '@/modules/auth/components/AuthProvider';
import { CartSidebar } from '@/modules/cart/components/CartSidebar/CartSidebar';
import { UnavailableProductsGuard } from '@/modules/cart/components/UnavailableProductsGuard/UnavailableProductsGuard';
import { CheckoutProvider } from '@/modules/checkout/components/CheckoutProvider';
import { ErrorBoundary } from '@/modules/errors/components/ErrorBoundary';
import { Freshdesk } from '@/modules/freshdesk/components/Freshdesk/Freshdesk';
import { NavBarBoxProvider } from '@/modules/layout/components/NavBarBoxProvider';
import { MetaTags } from '@/modules/meta/components/MetaTags';
import { store } from '@/store/store';
import { ApiProvider } from '@lib/api/components/ApiProvider';
import { restoreApolloClient } from '@lib/api/create-api-client';
import { publicEnv } from '@lib/env/public-env-vars';
import { createFirebaseAuthClient } from '@lib/firebase-auth-client/firebase-auth-client';
import { NoJsImageStyle } from '@lib/img/components/NoJsImageStyle';
import { withLDProvider } from '@lib/launch-darkly/client/ld-client';
import { Media } from '@lib/theme/components/Media';
import { TooltipContainer } from '@lib/theme/components/Tooltip/TooltipContainer';

import nextI18NextConfig from '../../next-i18next.config';

import { DelioServerSideProps } from './types';

import 'tailwindcss/tailwind.css';
import '@adyen/adyen-web/dist/adyen.css';
import 'overlayscrollbars/overlayscrollbars.css';
import 'keen-slider/keen-slider.min.css';

import './global.css';
import './overlay-scrollbars.css';
import '../modules/payment-dropin/services/PaymentDropin.styles.css';
import 'react-datepicker/dist/react-datepicker.css';

const CategoriesModal = dynamic(
  async () =>
    (await import('@/modules/layout/components/CategoriesModal'))
      .CategoriesModal,
  { ssr: false }
);

const LocationModal = dynamic(
  async () =>
    (await import('@/modules/location/components/LocationModal/LocationModal'))
      .LocationModal,
  { ssr: false }
);

const App = ({
  Component,
  pageProps,
}: AppProps<Partial<DelioServerSideProps>>) => {
  const apiGatewayClientState = pageProps.API_GW_CLIENT_PROP_NAME;

  // API gateway client is kept in state to avoid re-creating it.
  const apiGatewayClient = useMemo(
    () => getApiGatewayClient(apiGatewayClientState ?? null),
    [apiGatewayClientState]
  );

  const session = pageProps.SESSION_STATE;

  const isUserLoggedIn = Boolean(
    session?.data?.attributes?.userProfile &&
      !session?.data?.attributes?.userProfile?.isAnonymous
  );

  useEffect(() => {
    if (!apiGatewayClientState) return;
    restoreApolloClient(apiGatewayClient, apiGatewayClientState);
  }, [apiGatewayClient, apiGatewayClientState]);

  useSegmentAnalytics({ isUserLoggedIn });
  useVersionCheck();
  useGlobalToastMessages();

  return (
    <ErrorBoundary>
      <Head>
        <meta
          name="facebook-domain-verification"
          content="3fwhzabynj1xqjjyttaj40mhxv49ms"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1"
        />
        <NoJsImageStyle />
      </Head>
      {publicEnv?.NEXT_PUBLIC_GTM_KEY && (
        <>
          {/* eslint-disable react/jsx-no-literals */}
          <Script id="gtm-datalayer">
            window.dataLayer = window.dataLayer || [];
          </Script>
          <Script id="gtm">
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
             new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
             j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
             'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
             })(window,document,'script','dataLayer','${publicEnv.NEXT_PUBLIC_GTM_KEY}');`}
          </Script>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${publicEnv.NEXT_PUBLIC_GTM_KEY}`}
          />
          <Script id="gtm-push">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            };
            gtag('js', new Date());
            gtag('config', '${publicEnv.NEXT_PUBLIC_GTM_KEY}');
            `}
          </Script>
        </>
      )}

      <ApiProvider client={apiGatewayClient}>
        <MetaTags />
        <Provider store={store}>
          <AuthProvider
            firebaseAuthClient={firebaseAuthClient}
            session={session}
            onSessionDestroyed={handleSessionDestroyed}
          >
            <Freshdesk session={session} />
            <UtmProvider>
              <TrackPageEvent />
              <NavBarBoxProvider>
                <CheckoutProvider>
                  <Component {...pageProps} />
                </CheckoutProvider>
                <Media clientOnly>
                  <CartSidebar />
                  <UnavailableProductsGuard />
                  <LocationModal />
                  <CategoriesModal />
                </Media>
              </NavBarBoxProvider>
            </UtmProvider>
          </AuthProvider>
        </Provider>
      </ApiProvider>

      <TooltipContainer />
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
        containerClassName={cn('mt-2')}
        containerStyle={{
          zIndex: 9999,
        }}
      />
    </ErrorBoundary>
  );
};

const firebaseAuthClient = createFirebaseAuthClient({
  apiKey: publicEnv.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: publicEnv.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
});

const getApiGatewayClient = (state: NormalizedCacheObject | null) => {
  if (typeof window === 'undefined') {
    const { createApiGatewayClientCache } =
      require('@/config/apiGatewayClientCache') as typeof import('@/config/apiGatewayClientCache');
    return createApiGatewayClientCache(state);
  }

  return createApiGatewayClient(state);
};

const handleSessionDestroyed = () => {
  if (typeof window === 'undefined') return;

  // We keep selected slot & some UTM related stuff in local storage.
  // That's also worth cleaning with the destroyed session.
  localStorage.clear();

  // Reload the page since it ensures we won't run into some issues with the
  // cache leftovers in @apollo/client's cache.
  // We did try to `client.resetStore()` and even to recreate the client
  // from scratch after the user signs out. Both attempts failed at some
  // point. Sometimes there was an infinite loader, other times there was
  // random error while app was trying to refresh the session just after
  // signing out.
  window.location.reload();
};

/**
 * A workaround for a generic type not being applied to `pageProps`
 * in `AppProps` from `next/app`
 */
type AppProps<P> = Omit<NextAppProps<P>, 'pageProps'> & {
  pageProps: P;
};

export default withLDProvider({
  clientSideID: publicEnv.NEXT_PUBLIC_LAUNCHDARKLY_SDK_CLIENT || '',
  context: {
    kind: 'user',
    key: 'anonymous',
  },
})(appWithTranslation(App, nextI18NextConfig) as ComponentType);
