import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type NumberAuthState = {
  verificationCode: string | undefined;
  customToken: string | undefined;
};

const initialState: NumberAuthState = {
  verificationCode: undefined,
  customToken: undefined,
};

export const numberAuthSlice = createSlice({
  name: 'numberAuth',
  initialState,
  reducers: {
    setVerificationCodeAndToken: (
      state,
      action: PayloadAction<NumberAuthState>
    ) => {
      state.verificationCode = action.payload.verificationCode;
      state.customToken = action.payload.customToken;
    },
  },
});

export const { setVerificationCodeAndToken } = numberAuthSlice.actions;

export default numberAuthSlice.reducer;
