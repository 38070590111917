import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import { CustomerProfilePartsFragmentDoc } from '../../fragments/CustomerProfileFragment/CustomerProfileFragment.common.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type CustomerProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerProfileQuery = { readonly __typename: 'Query', readonly customerProfile: { readonly __typename: 'CustomerProfile', readonly firstName?: string | null, readonly lastName?: string | null, readonly dateOfBirth?: string | null, readonly contactEmail: string, readonly version: number, readonly alcoholSaleConsent: boolean, readonly phoneNumberVerified: boolean, readonly phoneNumber?: { readonly __typename: 'PhoneNumber', readonly countryCode: string, readonly nationalNumber: string } | null, readonly ourMarketingConsents: { readonly __typename: 'MarketingConsents', readonly alcohol: boolean, readonly appNotification: boolean, readonly sms: boolean, readonly email: boolean } } };


export const CustomerProfileDocument = gql`
    query CustomerProfile {
  customerProfile {
    ...CustomerProfileParts
  }
}
    ${CustomerProfilePartsFragmentDoc}`;

/**
 * __useCustomerProfileQuery__
 *
 * To run a query within a React component, call `useCustomerProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerProfileQuery(baseOptions?: Apollo.QueryHookOptions<CustomerProfileQuery, CustomerProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerProfileQuery, CustomerProfileQueryVariables>(CustomerProfileDocument, options);
      }
export function useCustomerProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerProfileQuery, CustomerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerProfileQuery, CustomerProfileQueryVariables>(CustomerProfileDocument, options);
        }
export function useCustomerProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerProfileQuery, CustomerProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerProfileQuery, CustomerProfileQueryVariables>(CustomerProfileDocument, options);
        }
export type CustomerProfileQueryHookResult = ReturnType<typeof useCustomerProfileQuery>;
export type CustomerProfileLazyQueryHookResult = ReturnType<typeof useCustomerProfileLazyQuery>;
export type CustomerProfileSuspenseQueryHookResult = ReturnType<typeof useCustomerProfileSuspenseQuery>;
export type CustomerProfileQueryResult = Apollo.QueryResult<CustomerProfileQuery, CustomerProfileQueryVariables>;