export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Currency: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  JSON: { input: unknown; output: unknown; }
  JSONObject: { input: unknown; output: unknown; }
  Long: { input: number; output: number; }
};

export enum Brand {
  Delio = 'DELIO',
  Jush = 'JUSH'
}

/** One of the two types of billing address a customer can have (other being "personal").Distinguished by presence of "vatId" and "company" fields */
export type CompanyBillingAddressInput = {
  readonly apartment?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly company: Scalars['String']['input'];
  readonly countryCode: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly postalCode: Scalars['String']['input'];
  readonly streetName?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber: Scalars['String']['input'];
  readonly vatId: Scalars['String']['input'];
};

export type CoordinatesInput = {
  readonly lat: Scalars['Float']['input'];
  readonly long: Scalars['Float']['input'];
};

export type CustomerOneBrandDiscountCodesFilters = {
  readonly brand?: InputMaybe<Brand>;
};

export type CustomerOneBrandOrderFilter = {
  readonly brand?: InputMaybe<Brand>;
  readonly state?: InputMaybe<ReadonlyArray<CustomerOrderStateFilter>>;
};

export enum CustomerOrderDiscountCodeState {
  Active = 'ACTIVE',
  DoesNotMatchCart = 'DOES_NOT_MATCH_CART',
  Inactive = 'INACTIVE',
  Invalid = 'INVALID',
  MaxApplicationReached = 'MAX_APPLICATION_REACHED'
}

export enum CustomerOrderState {
  Arriving = 'ARRIVING',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  InDelivery = 'IN_DELIVERY',
  InFulfillment = 'IN_FULFILLMENT'
}

export enum CustomerOrderStateFilter {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Open = 'OPEN'
}

export enum CustomerOrdersSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  LastModifiedAtAsc = 'LAST_MODIFIED_AT_ASC',
  LastModifiedAtDesc = 'LAST_MODIFIED_AT_DESC'
}

export enum DelioBrandStatus {
  Available = 'AVAILABLE',
  AvailableNite = 'AVAILABLE_NITE',
  NotAvailable = 'NOT_AVAILABLE'
}

export enum DelioContext {
  Delio = 'DELIO',
  DelioNite = 'DELIO_NITE'
}

export enum JushBrandStatus {
  Available = 'AVAILABLE',
  Closed = 'CLOSED',
  EmergencyClosed = 'EMERGENCY_CLOSED',
  NotAvailable = 'NOT_AVAILABLE'
}

export type MarketingConsentsInput = {
  readonly alcohol?: InputMaybe<Scalars['Boolean']['input']>;
  readonly appNotification?: InputMaybe<Scalars['Boolean']['input']>;
  readonly email?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sms?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum OrderEtaDelay {
  Long = 'LONG',
  None = 'NONE',
  Short = 'SHORT'
}

/** One of the two types of billing address a customer can have (other being "company").Distinguished by presence of "firstName" and "lastName" fields */
export type PersonalBillingAddressInput = {
  readonly apartment?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly countryCode: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly postalCode: Scalars['String']['input'];
  readonly streetName?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber: Scalars['String']['input'];
};

export type PhoneNumberInput = {
  readonly countryCode: Scalars['String']['input'];
  readonly nationalNumber: Scalars['String']['input'];
};

export type ShippingAddressInput = {
  readonly apartment?: InputMaybe<Scalars['String']['input']>;
  readonly city: Scalars['String']['input'];
  readonly countryCode: Scalars['String']['input'];
  readonly deliveryNotes?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly floor?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly lat: Scalars['Float']['input'];
  readonly long: Scalars['Float']['input'];
  readonly phoneNumber?: InputMaybe<PhoneNumberInput>;
  readonly postalCode: Scalars['String']['input'];
  readonly streetName?: InputMaybe<Scalars['String']['input']>;
  readonly streetNumber: Scalars['String']['input'];
};

export type SignUpInput = {
  readonly contactEmail?: InputMaybe<Scalars['String']['input']>;
  readonly dateOfBirth: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly ourMarketingConsents: MarketingConsentsInput;
  readonly partnersMarketingConsents: MarketingConsentsInput;
  readonly phoneNumber: PhoneNumberInput;
  readonly verificationCode: Scalars['String']['input'];
};

export type UpdateCustomerProfileInput = {
  readonly alcoholSaleConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly contactEmail?: InputMaybe<Scalars['String']['input']>;
  readonly dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly ourMarketingConsents?: InputMaybe<MarketingConsentsInput>;
  readonly partnersMarketingConsents?: InputMaybe<MarketingConsentsInput>;
};
