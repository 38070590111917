import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
export type CustomerProfilePartsFragment = { readonly __typename: 'CustomerProfile', readonly firstName?: string | null, readonly lastName?: string | null, readonly dateOfBirth?: string | null, readonly contactEmail: string, readonly version: number, readonly alcoholSaleConsent: boolean, readonly phoneNumberVerified: boolean, readonly phoneNumber?: { readonly __typename: 'PhoneNumber', readonly countryCode: string, readonly nationalNumber: string } | null, readonly ourMarketingConsents: { readonly __typename: 'MarketingConsents', readonly alcohol: boolean, readonly appNotification: boolean, readonly sms: boolean, readonly email: boolean } };

export const CustomerProfilePartsFragmentDoc = gql`
    fragment CustomerProfileParts on CustomerProfile {
  firstName
  lastName
  dateOfBirth
  contactEmail
  version
  alcoholSaleConsent
  phoneNumber {
    countryCode
    nationalNumber
  }
  ourMarketingConsents {
    alcohol
    appNotification
    sms
    email
  }
  phoneNumberVerified
}
    `;