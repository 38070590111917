import dynamic from 'next/dynamic';

import { CurrentCartQuery } from '@/core/graphql/queries/CurrentCart/CurrentCart.delio.generated';
import { useCurrentCart } from '@/core/hooks/useCurrentCart';
import { useHideChat } from '@/core/hooks/useHideChat';
import { useSession } from '@/modules/auth/hooks/useSession';
import { useStore } from '@/modules/store/hooks/useStore';
import { selectUnavailableProductsModalIsOpen } from '@/store/selectors/unavailableProductsModalSelectors';
import { closeModal, openModal } from '@/store/slices/unavailableProductsModal';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

import { selectUnavailableLineItems } from '../../selectors/selectUnavailableLineItems';

const UnavailableProductsGuardInner = dynamic(
  async () =>
    (await import('./components/UnavailableProductsGuardInner'))
      .UnavailableProductsGuardInner,
  { ssr: false }
);

export const UnavailableProductsGuard = () => {
  const { status } = useSession('createSessionState');
  const { store, loading } = useStore();
  const dispatch = useAppDispatch();
  const isModalOpen = useAppSelector(selectUnavailableProductsModalIsOpen);

  const onCompleted = (data: CurrentCartQuery) => {
    const lineItems = data?.currentCart.lineItems || [];
    const unavailableLineItems = selectUnavailableLineItems(lineItems);

    if (unavailableLineItems.length > 0) {
      dispatch(openModal());
    }
  };

  const currentCartResult = useCurrentCart({ onCompleted });

  const isOpen = Boolean(
    status === 'resolved' &&
      !loading &&
      !currentCartResult.loading &&
      store &&
      isModalOpen
  );

  useHideChat(isOpen);

  const handleCloseModal = () => dispatch(closeModal());

  if (!isOpen) return null;

  return <UnavailableProductsGuardInner onClose={handleCloseModal} />;
};
