import { ParsedUrlQuery } from 'querystring';

import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';

import useLocalStorageState from '@/core/hooks/useLocalStorageState';
import { FCC } from '@/types/common';

import { UTM_KEYS, UtmContext, UtmObject } from '../contexts/UtmContext';

/*
  Detect if the current browser is a webview. If so, provide a no-op implementation of UTM provider.
  use-local-storage-state causes problems on webviews that have localStorage disabled.
*/
export const UtmProvider: FCC = ({ children }) => (
  <BrowserUtmProvider>{children}</BrowserUtmProvider>
);

// currenlty it's being used to pass UTM data to sendOrderCreatedEvent
// and sent with events "purchase" to GA and "Order Created" to Segment
// for reference: https://jushapp.atlassian.net/browse/WEB2-1114
const BrowserUtmProvider: FCC = ({ children }) => {
  const { query } = useRouter();
  const [utm, setUtm, { removeItem }] = useLocalStorageState<UtmObject | null>(
    'utm',
    {
      defaultValue: buildUtm(query),
    }
  );

  useEffect(() => {
    const newUtm = buildUtm(query);
    if (newUtm !== null) {
      setUtm(newUtm);
    }
  }, [query, setUtm]);

  const clearUtm = useCallback(() => {
    removeItem();
  }, [removeItem]);

  const utmContextProps = useMemo(() => ({ utm, clearUtm }), [clearUtm, utm]);

  return (
    <UtmContext.Provider value={utmContextProps}>
      {children}
    </UtmContext.Provider>
  );
};

const buildUtm = (query: ParsedUrlQuery) => {
  if (UTM_KEYS.every((key) => query[key] === undefined)) return null;

  const utmObject = UTM_KEYS.reduce(
    (acc, key) => ({ ...acc, [key]: query[key] }),
    {} as UtmObject
  );
  return utmObject;
};
