import { createContext } from 'react';

export const SignUpContext = createContext<SignUpContextType>({
  get currentTab(): never {
    throw missingSignUpContextError;
  },
  get setCurrentTab(): never {
    throw missingSignUpContextError;
  },
});

const missingSignUpContextError = Error('SignUpContext is not provided');

export interface SignUpContextType {
  currentTab: SignUpAuthTabKey;
  setCurrentTab: (
    tab: SignUpAuthTabKey,
    params?: Record<string, string>
  ) => Promise<boolean>;
}

export type SignUpAuthTabKey =
  | 'sign-up'
  | 'phone-number-verification'
  | 'user-data-confirmation';
