export function goodTry<T>(tryFn: () => T): T | undefined {
  try {
    return tryFn();
  } catch {
    return undefined;
  }
}

// a wrapper for `JSON.parse()` that supports "undefined" value. otherwise,
// `JSON.parse(JSON.stringify(undefined))` returns the string "undefined" not the value `undefined`
export function parseJson(value: string): unknown {
  return value === 'undefined' ? undefined : JSON.parse(value);
}
