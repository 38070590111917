import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type PhoneVerificationState = {
  isNumberAlreadyRegistered: boolean;
  isChangingNumber: boolean;
  user: {
    firstName: string | undefined;
    lastName: string | undefined;
    phoneNumber?: {
      nationalNumber: string;
      countryCode: string;
    };
    marketingConsent: boolean | undefined;
    alcoholConsent: boolean | undefined;
  };
};

const initialState: PhoneVerificationState = {
  isNumberAlreadyRegistered: false,
  isChangingNumber: false,
  user: {
    alcoholConsent: undefined,
    marketingConsent: undefined,
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
  },
};

export const phoneVerificationSlice = createSlice({
  name: 'phone-verification',
  initialState,
  reducers: {
    setPhoneVerificationUser: (
      state,
      action: PayloadAction<PhoneVerificationState['user']>
    ) => {
      state.user = action.payload;
      state.isChangingNumber = false;
      state.isNumberAlreadyRegistered = false;
    },

    setPhoneVerificationChangingNumber: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isChangingNumber = action.payload;
    },

    setPhoneVerificationNumberAlreadyRegistered: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isNumberAlreadyRegistered = action.payload;
    },

    setPhoneVerificationInitial: () => initialState,
  },
});

export const {
  setPhoneVerificationUser,
  setPhoneVerificationChangingNumber,
  setPhoneVerificationNumberAlreadyRegistered,
  setPhoneVerificationInitial,
} = phoneVerificationSlice.actions;

export default phoneVerificationSlice.reducer;
