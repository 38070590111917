export const TooltipContainer = () => (
  <div
    id={TOOLTIP_CONTAINER_ID}
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      pointerEvents: 'none',
    }}
  />
);

export const TOOLTIP_CONTAINER_ID = 'tooltip-container';
