import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useHideChat } from '@/core/hooks/useHideChat';

import { CART_SIDEBAR_STATE } from '../../hooks/useCreateCartSidebarUrl';

const CartSidebarInner = dynamic(
  async () => (await import('./CartSidebarInner')).CartSidebarInner,
  { ssr: false }
);

export const CartSidebar = () => {
  const { query } = useRouter();
  const isCartSidebarOpen = query.cartSidebar === CART_SIDEBAR_STATE.OPEN;
  useHideChat(isCartSidebarOpen);

  return <CartSidebarInner open={isCartSidebarOpen} />;
};
