import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

import { CART_SIDEBAR_STATE } from '@/modules/cart/hooks/useCreateCartSidebarUrl';
import { FCC } from '@/types/common';

import {
  NavBarBoxContext,
  NavBarBoxValues,
} from '../contexts/NavBarBoxContext';

export const NavBarBoxProvider: FCC = ({ children }) => {
  const marketingTopBarRef = useRef<HTMLDivElement>(null);
  const navBarRef = useRef<HTMLDivElement>(null);
  const secondaryNavBarRef = useRef<HTMLDivElement>(null);

  const { query } = useRouter();
  const isCartSidebarOpen = query.cartSidebar === CART_SIDEBAR_STATE.OPEN;

  const [state, setState] = useState<NavBarBoxValues>({
    navBarBottomPosition: undefined,
    navBarRightPosition: undefined,
    navBarPaddingRight: undefined,
    secondaryNavBarBottomPosition: undefined,
  });

  const throttledSetState = useThrottledCallback(setState, throttleMs, {
    leading: true,
    trailing: true,
  });

  useEffect(() => {
    const handler = () => {
      const navBarEl = navBarRef.current;
      const secondaryNavBarEl = secondaryNavBarRef.current;

      if (!navBarEl) return;

      throttledSetState({
        secondaryNavBarBottomPosition:
          secondaryNavBarEl?.getBoundingClientRect().bottom ?? 0,
        navBarBottomPosition: navBarEl.getBoundingClientRect().bottom ?? 0,
        navBarRightPosition: isCartSidebarOpen ? window.innerWidth : undefined,
        navBarPaddingRight: isCartSidebarOpen
          ? Math.floor(
              window.innerWidth - document.body.getBoundingClientRect().width
            )
          : 0,
      });
    };

    handler();

    // ResizeObserver here do the better job that window.onresize, since it
    // also runs when content of the document body changes.
    const resizeObserver = new ResizeObserver(handler);
    resizeObserver.observe(document.body);

    window.addEventListener('scroll', handler, {
      passive: true,
    });
    return () => {
      resizeObserver.unobserve(document.body);
      window.removeEventListener('scroll', handler);
      throttledSetState.cancel();
    };
  }, [isCartSidebarOpen, throttledSetState]);

  const navBarContextProps = useMemo(
    () => ({
      ...state,
      navBarRef,
      secondaryNavBarRef,
      marketingTopBarRef,
    }),
    [state]
  );

  return (
    <NavBarBoxContext.Provider value={navBarContextProps}>
      {children}
    </NavBarBoxContext.Provider>
  );
};

const throttleMs = 100;
