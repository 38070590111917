import { configureStore } from '@reduxjs/toolkit';

import { publicEnv } from '@lib/env/public-env-vars';

import categoriesReducer from './slices/categoriesSlice';
import geolocationPermissionsReducer from './slices/geolocationPermissionsSlice';
import landingSliderDropdownReducer from './slices/landingSliderDropdownSlice';
import locationSchedulerReducer from './slices/locationScheduler';
import modalsReducer from './slices/modalsSlice';
import numberAuthReducer from './slices/numberAuthSlice';
import phoneVerificationReducer from './slices/phoneVerificationSlice';
import profileReducer from './slices/profileSlice';
import slotsReducer from './slices/slotsSlice';
import unavailableProductsModalReducer from './slices/unavailableProductsModal';

export const store = configureStore({
  reducer: {
    geolocationPermissions: geolocationPermissionsReducer,
    categories: categoriesReducer,
    slots: slotsReducer,
    locationScheduler: locationSchedulerReducer,
    landingSliderDropdown: landingSliderDropdownReducer,
    modals: modalsReducer,
    profile: profileReducer,
    phoneVerification: phoneVerificationReducer,
    numberAuth: numberAuthReducer,
    unavailableProductsModal: unavailableProductsModalReducer,
  },
  devTools: publicEnv.NEXT_PUBLIC_ENVIRONMENT !== 'prod',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: { warnAfter: 128 },
    }),
});
