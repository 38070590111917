import { LineItem } from '@/core/types';
import { selectQuantityOverStock } from '@/modules/cart/selectors/selectQuantityOverStock';

const selectUnpublishedLineItems = (lineItem: LineItem) =>
  !lineItem?.product?.isPublished;

export const selectUnavailableLineItems = (lineItems: Readonly<LineItem[]>) => {
  const lineItemsOverStock = lineItems.filter(
    (lineItem) => selectQuantityOverStock(lineItem) > 0
  );

  const unpublishedLineItems = lineItems
    .filter(selectUnpublishedLineItems)
    .map((item) => ({ ...item, isUnpublished: true }));

  return [...lineItemsOverStock, ...unpublishedLineItems];
};
