import { setUser } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { analytics } from '@/config/analytics';
import { logger } from '@/config/logger';
import { FCC } from '@/types/common';
import { publicEnv } from '@lib/env/public-env-vars';
import { createFirebaseAuthClient } from '@lib/firebase-auth-client/firebase-auth-client';

import { AuthContext } from '../contexts/AuthContext';
import { SignUpAuthTabKey, SignUpContext } from '../contexts/SignUpContext';
import { useSessionManagement } from '../hooks/useSessionManagement';
import { selectAuthRole } from '../selectors/selectAuthRole';
import { Session } from '../types';

export const AuthProvider: FCC<AuthProviderProps> = ({
  children,
  firebaseAuthClient,
  session,
  onSessionDestroyed,
}) => {
  const {
    createSessionState,
    createSession,
    destroySessionState,
    destroySession,
    confirmUser,
  } = useSessionManagement({
    session,
    onSessionDestroyed,
  });
  const { query, push } = useRouter();
  const authModalQuery = query && query.authModal;
  const authRole = selectAuthRole(createSessionState.session);

  useSetSentryUser(session ?? createSessionState.session);
  useSetSegmentUser(session ?? createSessionState.session);

  const setCurrentTab = useCallback(
    (tab: SignUpAuthTabKey) => {
      const url = new URL(`${publicEnv.NEXT_PUBLIC_WEBSITE_URL}/sign-up`);
      url.searchParams.set('authModal', tab);
      return push(url, undefined, { shallow: true });
    },
    [push]
  );

  const authModalContextProps = useMemo(
    () => ({
      currentTab: getCurrentAuthTab(authModalQuery),
      setCurrentTab,
    }),
    [authModalQuery, setCurrentTab]
  );

  const authContextProps = useMemo(
    () => ({
      firebaseAuthClient,
      createSessionState,
      createSession,
      destroySessionState,
      destroySession,
      authRole,
      confirmUser,
    }),
    [
      authRole,
      createSession,
      createSessionState,
      destroySession,
      destroySessionState,
      firebaseAuthClient,
      confirmUser,
    ]
  );

  return (
    <SignUpContext.Provider value={authModalContextProps}>
      <AuthContext.Provider value={authContextProps}>
        {children}
      </AuthContext.Provider>
    </SignUpContext.Provider>
  );
};

const useSetSentryUser = (session: Session) => {
  const id = session?.data.id;

  useEffect(() => {
    if (typeof id !== 'string') return;
    if (id.length === 0) {
      logger.error('Cannot set user id in sentry due to missing session.id');
      return;
    }

    setUser({
      id,
    });
  }, [id]);
};
const useSetSegmentUser = (session: Session) => {
  const { id, attributes } = session?.data ?? {};
  useEffect(() => {
    if (typeof id !== 'string') return;
    if (id.length === 0) {
      logger.error('Cannot set user id in sentry due to missing session.id');
      return;
    }

    if (attributes?.userProfile.isAnonymous) return;
    analytics?.identify?.(id, {
      email: attributes?.userProfile.email,
    });

    return () => {
      analytics?.reset?.();
    };
  }, [id, attributes]);
};

const getCurrentAuthTab = (maybeTab: unknown): SignUpAuthTabKey => {
  if (
    maybeTab === 'sign-up' ||
    maybeTab === 'phone-number-verification' ||
    maybeTab === 'user-data-confirmation'
  ) {
    return maybeTab;
  }
  return 'sign-up';
};

export interface AuthProviderProps {
  children: ReactNode;
  firebaseAuthClient: ReturnType<typeof createFirebaseAuthClient>;
  session: Session;
  onSessionDestroyed?: () => void;
  onSessionCreated?: () => void;
}
