import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';
import { memo, ReactNode } from 'react';

import { Breakpoint, breakpoints } from '../config/breakpoints';
import { useMedia } from '../hooks/useMedia';

export const Media: MediaComponent = memo(
  ({ children, clientOnly, ...mediaSettings }) => {
    const { isOnClient } = useMedia();
    const avoidClientOnlyElementOnServerRender = clientOnly && !isOnClient;

    if (avoidClientOnlyElementOnServerRender) {
      return null;
    }

    if ('lessThan' in mediaSettings && 'greaterThanOrEqual' in mediaSettings) {
      return (
        <Between
          lessThan={mediaSettings.lessThan}
          greaterThanOrEqual={mediaSettings.greaterThanOrEqual}
        >
          {children}
        </Between>
      );
    }

    if ('lessThan' in mediaSettings) {
      return <LessThan value={mediaSettings.lessThan}>{children}</LessThan>;
    }

    if ('greaterThanOrEqual' in mediaSettings) {
      return (
        <GreaterThanOrEqual value={mediaSettings.greaterThanOrEqual}>
          {children}
        </GreaterThanOrEqual>
      );
    }

    // Disabled due to pure `children` is not a ReactElement
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
);

const Between: FCC<{
  lessThan: Exclude<Breakpoint, 'xs'>;
  greaterThanOrEqual: Breakpoint;
  children: ReactNode;
}> = ({ children, lessThan, greaterThanOrEqual }) => {
  const { windowInnerWidth } = useMedia();

  if (
    windowInnerWidth !== null &&
    windowInnerWidth < breakpoints[greaterThanOrEqual]
  ) {
    return null;
  }

  if (windowInnerWidth !== null && windowInnerWidth >= breakpoints[lessThan]) {
    return null;
  }

  return (
    <div
      className={cn(
        lessThanClassName[lessThan],
        greaterThanOrEqualClassName[greaterThanOrEqual]
      )}
    >
      {children}
    </div>
  );
};

const LessThan: FCC<{
  value: Exclude<Breakpoint, 'xs'>;
}> = memo(({ children, value }) => {
  const { windowInnerWidth } = useMedia();

  if (windowInnerWidth !== null && windowInnerWidth >= breakpoints[value]) {
    return null;
  }

  return (
    <div className={cn(lessThanClassName[value], 'contents')}>{children}</div>
  );
});

const lessThanClassName: Record<Exclude<Breakpoint, 'xs'>, string> = {
  xsm: 'xsm:hidden',
  sm: 'sm:hidden',
  md: 'md:hidden',
  lmd: 'lmd:hidden',
  slg: 'slg:hidden',
  lg: 'lg:hidden',
  xl: 'xl:hidden',
  lxl: 'lxl:hidden',
  '2xl': '2xl:hidden',
};

const GreaterThanOrEqual: FCC<{
  value: Breakpoint;
}> = memo(({ children, value }) => {
  const { windowInnerWidth } = useMedia();

  if (windowInnerWidth !== null && windowInnerWidth < breakpoints[value]) {
    return null;
  }

  return <div className={greaterThanOrEqualClassName[value]}>{children}</div>;
});

const greaterThanOrEqualClassName: Record<Breakpoint, string> = {
  xs: '',
  xsm: cn('hidden', 'xsm:contents'),
  sm: cn('hidden', 'sm:contents'),
  md: cn('hidden', 'md:contents'),
  lmd: cn('hidden', 'lmd:contents'),
  slg: cn('hidden', 'slg:contents'),
  lg: cn('hidden', 'lg:contents'),
  xl: cn('hidden', 'xl:contents'),
  lxl: cn('hidden', 'lxl:contents'),
  '2xl': cn('hidden', '2xl:contents'),
};

export type MediaComponent = FCC<MediaProps>;

export type MediaProps = Readonly<{
  /**
   * When `true` the component is never rendered on the server side.
   * This is useful for modals or other elements that we don't need on the
   * initial render. This way we can avoid redundant operations on the
   * server-side.
   */
  clientOnly?: boolean;
}> &
  Readonly<
    | {
        /** The content is visible on a breakpoint smaller than provided */
        lessThan: Exclude<Breakpoint, 'xs'>;
      }
    | {
        /** The content is visible on a breakpoint great or equal to provided */
        greaterThanOrEqual: Breakpoint;
      }
    | {
        lessThan: Exclude<Breakpoint, 'xs'>;
        greaterThanOrEqual: Breakpoint;
      }
    // eslint-disable-next-line @typescript-eslint/ban-types
    | {}
  >;
