import Script from 'next/script';

import {
  CustomerProfileQuery,
  useCustomerProfileLazyQuery,
} from '@/core/graphql/queries/CustomerProfile/CustomerProfile.common.generated';
import { Session } from '@/modules/auth/types';
import { useOrdersLazyQuery } from '@/modules/order/queries/Orders.delio.generated';
import { CustomerOrdersSort } from '@/types/gqlCommon.common.generated';
import { publicEnv } from '@lib/env/public-env-vars';
import packageJson from 'package.json';

import { loadFreshdesk } from '../../services/loadFreshdesk';

type FreshdeskProps = {
  session: Session;
};

export type CustomerProfile = NonNullable<
  CustomerProfileQuery['customerProfile']
>;

export const Freshdesk = ({ session }: FreshdeskProps) => {
  const userProfile = session?.data?.attributes?.userProfile;

  const isUserLoggedIn = Boolean(
    userProfile && !userProfile?.isAnonymous && userProfile?.isConfirmed
  );

  const [getCustomerProfile] = useCustomerProfileLazyQuery();
  const [getOrders] = useOrdersLazyQuery();

  const getCurrentOrderId = async () => {
    // cannot use useRouter here because its not recognize updates in query params
    const params = new URL(document.location.href).searchParams;
    const orderIdFromQueryParams = params.get('orderId');

    if (orderIdFromQueryParams) return orderIdFromQueryParams;

    return (
      await getOrders({
        variables: { limit: 1, sort: CustomerOrdersSort.CreatedAtDesc },
      })
    ).data?.orders?.results[0]?.orderNumber;
  };

  const handleLoad = () => {
    if (isUserLoggedIn) {
      loadFreshdesk(session);
    }

    if (!window?.fcWidget) return;

    window.fcWidget.on('widget:opened', async () => {
      const { data } = await getCustomerProfile();
      const orderId = await getCurrentOrderId();

      if (data?.customerProfile) {
        await window.fcWidget?.user.setProperties(
          createUserAttributes(data.customerProfile, session?.data.id, orderId)
        );
      }
    });
  };

  if (publicEnv.NEXT_PUBLIC_ENVIRONMENT !== 'prod') {
    return (
      <Script src="//eu.fw-cdn.com/12448275/507394.js" onLoad={handleLoad} />
    );
  }

  return (
    <Script
      src="https://wchat.freshchat.com/js/widget.js"
      onLoad={handleLoad}
    />
  );
};

const createUserAttributes = (
  customerProfile: Partial<CustomerProfile>,
  externalId?: string,
  currentOrderId?: string
) => {
  const { firstName, lastName, contactEmail, phoneNumber } = customerProfile;

  return {
    externalId,
    firstName: String(firstName),
    lastName: String(lastName),
    email: String(contactEmail),
    phone: String(phoneNumber?.nationalNumber),
    phoneCountryCode: String(phoneNumber?.countryCode),
    cf_app_name: 'web_delio',
    cf_app_version: packageJson.version,
    cf_device: window.navigator.userAgent,
    cf_order_number: currentOrderId,
  };
};
