import * as Types from '@/types/gqlCommon.common.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {"context":{"clientName":"common"}} as const;
export type BrandsDashboardQueryVariables = Types.Exact<{
  coordinates: Types.CoordinatesInput;
}>;


export type BrandsDashboardQuery = { readonly __typename: 'Query', readonly brandsDashboard: { readonly __typename: 'BrandsDashboard', readonly brands: ReadonlyArray<{ readonly __typename: 'DelioDashboardBrand', readonly status: Types.DelioBrandStatus, readonly darkstore?: { readonly __typename: 'Darkstore', readonly key: string, readonly hasConcessionGranted: boolean, readonly address: { readonly __typename: 'DarkstoreAddress', readonly streetNumber: string, readonly streetName: string } } | null } | { readonly __typename: 'JushDashboardBrand' }> } };


export const BrandsDashboardDocument = gql`
    query BrandsDashboard($coordinates: CoordinatesInput!) {
  brandsDashboard(coordinates: $coordinates) {
    brands {
      ... on DelioDashboardBrand {
        status
        darkstore {
          key
          hasConcessionGranted
          address {
            streetNumber
            streetName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useBrandsDashboardQuery__
 *
 * To run a query within a React component, call `useBrandsDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsDashboardQuery({
 *   variables: {
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useBrandsDashboardQuery(baseOptions: Apollo.QueryHookOptions<BrandsDashboardQuery, BrandsDashboardQueryVariables> & ({ variables: BrandsDashboardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandsDashboardQuery, BrandsDashboardQueryVariables>(BrandsDashboardDocument, options);
      }
export function useBrandsDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandsDashboardQuery, BrandsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandsDashboardQuery, BrandsDashboardQueryVariables>(BrandsDashboardDocument, options);
        }
export function useBrandsDashboardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrandsDashboardQuery, BrandsDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrandsDashboardQuery, BrandsDashboardQueryVariables>(BrandsDashboardDocument, options);
        }
export type BrandsDashboardQueryHookResult = ReturnType<typeof useBrandsDashboardQuery>;
export type BrandsDashboardLazyQueryHookResult = ReturnType<typeof useBrandsDashboardLazyQuery>;
export type BrandsDashboardSuspenseQueryHookResult = ReturnType<typeof useBrandsDashboardSuspenseQuery>;
export type BrandsDashboardQueryResult = Apollo.QueryResult<BrandsDashboardQuery, BrandsDashboardQueryVariables>;