import { HTTP_STATUS_CODE } from '@lib/api/types';

/**
 * This is a wrapper that we use to distinguish between errors thrown
 * by us and generic errors.
 *
 * `new RenderError(message)` should always get a human-readable and translated
 * error message.
 */
export class RenderError extends Error {
  statusCode = STATUS_CODE;

  constructor(
    message: string,
    statusCode?:
      | HTTP_STATUS_CODE.NOT_FOUND
      | HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR
  ) {
    super(message);
    this.statusCode = statusCode ?? this.statusCode;
  }
}

const STATUS_CODE = 500;
